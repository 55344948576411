import MyImage from '@shared/components/myImage'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import style from './style.module.scss'
import closeIcon from '@assets/images/icon/close-icon.svg'
import { REACT_APP_ENV } from '@shared-submodule/sm-constants'

function StickyAds() {
  const router = useRouter()
  const [isClosed, setClosed] = useState(false)
  // const adSlotRef = useRef(null)

  function handleClose() {
    setClosed(true)
    document.body.classList.add('stickyAdRemoved')
  }

  useEffect(() => {
    let currentAdSlot = null
    const googletag = window.googletag || { cmd: [] }

    // Clean up existing ad slot
    const cleanupAd = () => {
      if (currentAdSlot) {
        googletag?.cmd?.push(() => {
          googletag.destroySlots([currentAdSlot])
        })
        currentAdSlot = null
      }

      // Clear the div content
      const adContainer = document.getElementById('div-gpt-ad-1728476763530-0')
      if (adContainer) {
        adContainer.innerHTML = ''
      }
    }

    const showAd = () => {
      googletag?.cmd?.push(() => {
        cleanupAd() // Clean up before showing new ad

        const width = window.innerWidth
        if (width >= 728) {
          const adContainer = document.getElementById('div-gpt-ad-1728476763530-0')
          if (adContainer) {
            adContainer.style.display = 'none'
          }
        } else {
          currentAdSlot = googletag
            .defineSlot('/23202123844/CK_Desktop', [[320, 50]], 'div-gpt-ad-1728476763530-0')
            .addService(googletag.pubads())

          const adContainer = document.getElementById('div-gpt-ad-1728476763530-0')
          if (adContainer) {
            adContainer.style.minHeight = '50px'
            adContainer.style.display = 'block'
          }

          googletag.enableServices()
          googletag.display('div-gpt-ad-1728476763530-0')
        }
      })
    }

    const handleRouteChange = () => {
      setClosed(false)
      document.body.classList.remove('stickyAdRemoved')
      showAd()
    }

    // Initial ad display
    showAd()

    // Set up route change handler
    router?.events?.on('routeChangeComplete', handleRouteChange)

    // Cleanup function
    return () => {
      router?.events?.off('routeChangeComplete', handleRouteChange)
      cleanupAd()
    }
  }, [router?.events])

  if (isClosed) return null
  if (router.asPath.includes('/mobile')) return null
  if (REACT_APP_ENV === 'development') return null

  return (
    <div className={`${style?.sticky} d-md-none text-center c-transition start-0 end-0 position-fixed`}>
      <button className={`${style.closebtn} position-absolute border-0 mt-n2 me-3 end-0 br-xs`} onClick={handleClose}>
        <MyImage src={closeIcon} alt="Close" width="12" height="12" />
      </button>
      <div id="div-gpt-ad-1728476763530-0" />
    </div>
  )
}

export default StickyAds
